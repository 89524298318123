<ng-container *ngIf="!isEmpty(get(featureSolutionsRow, 'feature_solutions', ''))">
  <div *ngFor="let row of get(featureSolutionsRow, 'feature_solutions.feature_solutions', []); let i = index">
    <section id="rows" class="d-flex flex-column container-gridless feature-solutions">
      <div class="d-flex flex-row justify-content-center pb-2">
        <h2 class="text-center col-md-7">
          {{ get(featureSolutionsRow.feature_solutions, "feature_solutions_title", "") }}
        </h2>
      </div>
      <div class="d-flex flex-row flex-wrap justify-content-center">
        <div *ngFor="let solution of get(row, 'feature_solutions', [])" class="col-md-4 mb-2">
          <div class="d-flex flex-column card-1">
            <div id="cms-partial-category-feature-solutions-img" class="text-center">
              <img
                *ngIf="get(solution, 'image.href', '')"
                [src]="getWebpImg(solution.image.href)"
                [alt]="solution.image.title"
              />
            </div>
            <h5 class="text-center p-1 mb-0 mt-3">{{ get(solution, "label", "") }}</h5>
            <h4 class="text-center mt-0">{{ get(solution, "title", "") }}</h4>
            <hr />
            <div class="text-center spec" [innerHTML]="get(solution, 'solution_specs', '') | safe : 'html'"></div>
            <hr />
            <div class="d-flex flex-column justify-content-center">
              <h4 *ngIf="get(solution, 'price', '')" class="text-center">{{ get(solution, "price", "") }}</h4>
              <a
                *ngIf="get(solution, 'button_text_select', '')"
                class="button-primary button-base mt-2"
                [routerLink]="get(solution, 'spec_link.routerLink', '')"
                [queryParams]="get(solution, 'spec_link.queryParams', '')"
              >
                {{ get(solution, "button_text_select", "") }}
              </a>
              <button
                *ngIf="get(solution, 'inquiry_form.id', '') && get(solution, 'no_form', '') !== true"
                class="button-secondary button-base mt-2"
                (click)="
                  bootstrapMarketoService.openBootstrapMarketoDialog(get(solution, 'inquiry_form.id', ''), 'edit', {
                    title: get(solution, 'title', ''),
                    url: solutionUrl,
                    productInfo: {
                      mpn: get(
                        featureSolutionsRow,
                        'feature_solutions.feature_solutions[0].feature_solutions[' + i + '].part_number',
                        ''
                      ),
                      solutionTitle: get(
                        featureSolutionsRow,
                        'feature_solutions.feature_solutions[0].feature_solutions[' + i + '].solution_title',
                        ''
                      )
                    }
                  })
                "
              >
                Customize Your System
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-container>
<hr *ngIf="get(featureSolutionsRow, 'feature_solutions.split_line', '')" class="m-0" />
